/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from 'react'
import Img from 'gatsby-image'

// Images
import Quote from 'img/icon/quote.inline.svg'

// Components
import ButtonLine from 'components/elements/ButtonLine'

// Third Party
import styled, { css } from 'styled-components'
import parse from 'html-react-parser'
import { motion, useAnimation } from 'framer-motion'

const StyledQuotes = styled.div`
  position: relative;
`

const StyledImg = styled(Img)`
  height: 100%;
  width: 100%;
  position: absolute !important;
`

const ImgContainer = styled.div`
  position: relative;
  height: 100%;
  padding: 40px 0 40px 0;
`

const QuoteContent = styled.div`
  z-index: 1;
  p {
    color: ${props => props.theme.color.text.light};
    font-size: ${props => props.theme.font.size.mx};
  }
`

const QuoteWrapper = styled.div`
  p {
    font-weight: ${props => props.theme.font.weight.xl};

    @media screen and (max-width: 991px) {
      padding: 0 40px 0 40px;
    }
  }
`

const Button = styled.button`
  background-color: ${props => props.theme.color.face.light};
  opacity: 0.3;
  height: 15px;
  width: 15px !important;
  border-radius: 10px;
  padding: 0;

  ${props => props.active && css`
    opacity: 1;
  `}
`

const QuoteContainer = styled(motion.div)``

const Quotes = ({ noImage, className, content }) => {
  const [quoteIndex, setQuoteIndex] = useState(0)
  const [init] = useState(false)
  const quote = content.relation[quoteIndex]

  const controls = useAnimation()

  const updateQuote = (o) => {
    if (o > quoteIndex) {
      controls.start({
        x: [0, 100, -150, 0],
        opacity: [1, 0, 0, 0, 0.5, 1]
      })
    } else {
      controls.start({
        x: [0, -100, 150, 0],
        opacity: [1, 0, 0, 0, 0.5, 1]
      })
    }

 
    setQuoteIndex(o)
  }

  const dragFromLeft = (o) => {
    controls.start({
      x: [-50, -50, 150, 100, 50, 0],
      opacity: [0, 0, 0, 0, 0.5, 1]
    })
    setQuoteIndex(o)
  }

  const dragFromRight = (o) => {
    controls.start({
      x: [-150, -100, -50, 0],
      opacity: [0, 0, 0, 0, 0.5, 1]
    })
    setQuoteIndex(o)
  }

  const dragEvent = (e) => {
    if (e.point.x > 0) {
      if (quoteIndex - 1 < 0) {
        dragFromRight(content.relation.length - 1)
      } else {
        dragFromRight(quoteIndex - 1)
      }
    } else if (e.point.x < 0) {
      if (quoteIndex + 1 >= content.relation.length) {
        dragFromLeft(0)
      } else {
        dragFromLeft(quoteIndex + 1)
      }
    }
  }

  useEffect(() => {
    controls.start(i => ({
      x: 0,
      transition: { delay: i * 0.3 }
    }))
  }, [init])

  const debounce = (func, wait) => {
    let timeout;
  
    return function executedFunction(...args) {
      const later = () => {
        timeout = null;
        func(...args);
      };
  
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  return (
    <StyledQuotes className={`${className ? `${className}` : ``}`}>
      <ImgContainer className="d-flex align-items-center justify-content-center">
        {!noImage && (
          <StyledImg loading="eager" fadeIn={false} fluid={content.image.localFile.childImageSharp.fluid} alt="" />
        )}
        <div style={{ zIndex: 1 }} className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <QuoteContent>
                <div className="d-flex justify-content-center">
                  <Quote className="mb-3" />
                </div>
                <QuoteContainer
                  initial={{ x: 100 }}
                  drag={content.relation.length > 1 ? 'x' : false}
                  dragElastic={0.1}
                  dragConstraints={{ left: 0, right: 0}}
                  onDrag={debounce((e, i) => {
                    dragEvent(i)
                  }, 100)}
                  animate={controls}
                >
                  <QuoteWrapper>
                    {parse(quote.acf.quote.text)}
                  </QuoteWrapper>
                  <p className="pt-4 text-center">{quote.acf.quote.name}</p>
                  <div className="d-flex justify-content-center pt-5">
                    <ButtonLine className="d-flex align-items-center" icon='arrow' to={quote.path}>
                      <p className="mb-0 pl-3">Lees de hele case</p>
                    </ButtonLine>
                  </div>
                </QuoteContainer>
                {content.relation.length > 1 && (
                <div className="d-flex justify-content-center pt-4">
                  {content.relation.map((qu, index) => (
                    <Button className="mr-2" active={index === quoteIndex} key={qu.wordpress_id} type="button" onClick={() => updateQuote(index)} />
                  ))}
                </div>
                )}
              </QuoteContent>
            </div>
          </div>
        </div>
        
      </ImgContainer>
    </StyledQuotes>
  )
}

export default Quotes